import { createSlice } from '@reduxjs/toolkit';
import { storage } from '@/utils/browser';

import * as thunks from './thunks';

import {
	handleAsyncThunkActionState,
	initialAsyncState,
	isAsyncThunkActionWithPrefix
} from '../utils';
import {
	STORAGE_IMAGE_EXP_MINS,
	clearStorageImages,
	defaultPositions,
	defaultSettings,
	getCurrentImage,
	getCurrentSettings,
	getUpdatedImage,
	getUpdatedPositions,
	getUpdatedSettings,
	imageStateToPipeline,
	localBackgrounds,
	removeStoredImage,
	saveImage
} from './utils';

const defaultToolbarSettings = {
	section: 'bg',
	tab: 'photo',
	subsection: 'brush'
};

const sectionTabs = {
	bg: ['photo', 'color', 'blur'],
	actions: ['erase', 'restore']
};

const subSectionTabs = {
	erase: ['brush', 'segment']
};

const initialState = {
	...defaultToolbarSettings,
	editView: false,
	downloadImage: false,
	bulkMode: false,
	imagesReady: false,
	selectedImage: null,
	images: [],
	file: null,
	localFiles: [],
	backgrounds: localBackgrounds,
	imageSize: null,
	fetchCredits: initialAsyncState,
	dropzoneProps: null
};

const editorSlice = createSlice(
	{
		name: 'editor',
		initialState,
		reducers: {
			clearData() {
				return initialState;
			},
			clearImages(state) {
				clearStorageImages();
				state.images = [];
				state.selectedImage = null;
			},
			clearDropzoneProps(state) {
				state.dropzoneProps = null;
			},
			setDropzoneProps(state, action) {
				state.dropzoneProps = action.payload;
			},
			setFile(state, action) {
				state.file = action.payload;
			},
			setEditView(state, action) {
				state.editView = action.payload;
			},
			setImageReady(state, action) {
				state.images = getUpdatedImage(state.images, action.payload.id, {
					ready: action.payload.ready
				});

				if (action.payload.ready && action.payload.localId) {
					state.localFiles = state.localFiles.filter(
						item => item.id !== action.payload.localId
					);
				}
			},
			addLocalFiles(state, action) {
				state.localFiles = [...state.localFiles, ...action.payload];
				state.selectedImage = null;
				state.section = defaultToolbarSettings.section;
				state.tab = defaultToolbarSettings.tab;
			},
			clearLocal(state) {
				state.localFiles = [];
			},
			addImage(state, action) {
				state.images = [...state.images, action.payload];
			},
			updateImageCroppedUrl(state, action) {
				state.images = getUpdatedImage(state.images, state.selectedImage, {
					cropped: { path: action.payload }
				});
			},
			addImages(state, action) {
				state.images = [...state.images, ...action.payload];
			},
			removeImage(state, action) {
				removeStoredImage(action.payload);

				const newImages = state.images.filter(
					item => item.id !== action.payload
				);

				const newSelected =
					newImages.findLast(item => !item.error)?.id || null;

				storage.save(
					'lastSelectedImage',
					newSelected,
					STORAGE_IMAGE_EXP_MINS
				);

				state.selectedImage = newSelected;
				state.images = state.images.filter(
					item => item.id !== action.payload
				);
			},
			setBulk(state, action) {
				state.bulkMode = action.payload;
			},
			setSection(state, action) {
				state.section = action.payload;
				state.tab = sectionTabs?.[action.payload]?.[0];
			},
			setSubSection(state, action) {
				state.subsection = action.payload;
			},
			setTab(state, action) {
				state.tab = action.payload;
				state.subsection = subSectionTabs?.erase?.[0];
			},
			selectImage(state, action) {
				storage.save('lastSelectedImage', action.payload);

				state.selectedImage = action.payload;
				state.section = defaultToolbarSettings.section;
				state.tab = defaultToolbarSettings.tab;
			},
			setZoom(state, { payload }) {
				state.images = getUpdatedSettings(
					state.images,
					state.selectedImage,
					{
						zoomLevel: payload
					}
				);
			},
			setBackgroundImg(state, { payload }) {
				state.images = getUpdatedSettings(
					state.images,
					state.selectedImage,
					{
						background: payload,
						...(payload === null ? { activeBlur: false } : {})
					}
				);
			},
			setBackgroundColor(state, { payload }) {
				state.images = getUpdatedSettings(
					state.images,
					state.selectedImage,
					{
						backgroundColor: payload,
						...(payload ? { activeBlur: false, background: null } : {})
					}
				);
			},
			setActiveBlur(state, { payload = false }) {
				state.images = getUpdatedSettings(
					state.images,
					state.selectedImage,
					{
						activeBlur: payload
					}
				);
			},
			setBlur(state, { payload = 0.5 }) {
				state.images = getUpdatedSettings(
					state.images,
					state.selectedImage,
					{
						blur: payload
					}
				);
			},
			setBlurWidth(state, { payload = null }) {
				state.images = getUpdatedSettings(
					state.images,
					state.selectedImage,
					{
						blurWidth: payload
					}
				);
			},
			setMagicBrush(state, { payload = false }) {
				state.images = getUpdatedSettings(
					state.images,
					state.selectedImage,
					{
						magicBrush: payload
					}
				);
			},
			setBrushSize(state, { payload = 20 }) {
				state.images = getUpdatedSettings(
					state.images,
					state.selectedImage,
					{
						brushSize: payload
					}
				);
			},
			setDownload(state, { payload = null }) {
				state.downloadImage = payload;
			},
			setImageSize(state, { payload }) {
				state.imageSize = payload;
			},
			addCustomBackground(state, action) {
				const { customBackgrounds = [] } = getCurrentSettings(
					state.images,
					state.selectedImage
				);

				state.images = getUpdatedSettings(
					state.images,
					state.selectedImage,
					{
						customBackgrounds: [...customBackgrounds, action.payload]
					}
				);
			},
			removeCustomBackground(state, action) {
				const { customBackgrounds = [] } = getCurrentSettings(
					state.images,
					state.selectedImage
				);

				state.images = getUpdatedSettings(
					state.images,
					state.selectedImage,
					{
						customBackgrounds: customBackgrounds.filter(
							item => item.id !== action.payload.id
						)
					}
				);
			},
			saveInStack(state, action) {
				const currentImage = getCurrentImage(
					state.images,
					state.selectedImage
				);

				const { undoStack: currentUndoStack = [] } =
					currentImage?.settings || {};

				const newSettings = {
					...(currentImage?.settings || {}),
					...(action.payload?.settings || {})
				};

				delete newSettings.undoStack;
				delete newSettings.redoStack;

				state.images = getUpdatedSettings(
					state.images,
					state.selectedImage,
					{
						undoStack: [
							...currentUndoStack,
							{
								settings: newSettings,
								pipeline: imageStateToPipeline(currentImage),
								sync: action.payload?.sync
							}
						],
						redoStack: [],
						hasChanges: !action.payload?.sync
					}
				);
			},
			updateImageHasChanges(state, { payload }) {
				state.images = getUpdatedSettings(
					state.images,
					state.selectedImage,
					{
						hasChanges: payload
					}
				);
			},
			updateBaseBg(state, { payload }) {
				state.images = getUpdatedImage(state.images, state.selectedImage, {
					baseBackground: payload
				});
			},
			setImagePositions(state, action) {
				state.images = getUpdatedPositions(
					state.images,
					state.selectedImage,
					action.payload
				);
			},
			restorePositions(state) {
				state.images = state.images.map(item => {
					if (item.id !== state.selectedImage) return item;

					return {
						...item,
						settings: {
							...item.settings,
							zoomLevel: defaultSettings.zoomLevel
						},
						positions: defaultPositions
					};
				});
			},
			updateSettings(state, { payload = {} }) {
				state.images = getUpdatedSettings(
					state.images,
					state.selectedImage,
					payload
				);
			},
			centerImage(state) {
				state.images = state.images.map(item => {
					if (item.id !== state.selectedImage) return item;

					const newCoords = item.settings?.coords
						? {
								coords: [
									item.settings?.crop?.left || 0,
									item.settings?.crop?.top || 0
								]
							}
						: {};

					window.mainCanvas?.fire?.('image:centered');

					return {
						...item,
						settings: {
							...item.settings,
							angle: 0,
							origin: [0, 0],
							scale: [1, 1],
							...newCoords
						}
					};
				});
			},
			setSelectionView(state, { payload = false }) {
				state.selectionView = payload;
			}
		},
		extraReducers(builder) {
			function applyStack(state, action) {
				state.images = action.payload?.newImages || state.images;
			}

			function handleRemove(state, action) {
				state.images = [
					...state.images,
					{
						...action.payload,
						settings: thunks.getMergedSettings(action.payload?.settings),
						positions: defaultPositions
					}
				];
				state.localFiles = state.localFiles.map(item => {
					if (item.id !== action.payload.localId) {
						return item;
					}

					return {
						...item,
						hideThumb: true
					};
				});

				if (action.payload?.id) {
					saveImage(
						action.payload.id,
						{
							...action.payload,
							settings: defaultSettings
						},
						STORAGE_IMAGE_EXP_MINS
					);
					storage.save(
						'lastSelectedImage',
						action.payload.id,
						STORAGE_IMAGE_EXP_MINS
					);

					state.selectedImage = action.payload.id;
				}
			}

			function handleInit(state, action) {
				state.images = [...(action.payload.images || [])];

				if (action.payload.selectedImage) {
					state.selectedImage = action.payload.selectedImage;
				}
			}

			function handleEdit(state, action) {
				clearStorageImages();
				state.selectedImage = action.payload.id;
				state.editView = true;
				state.images = [];
				state.localFiles = [action.payload];
				state.section = defaultToolbarSettings.section;
				state.tab = defaultToolbarSettings.tab;
				handleRemove(state, action);
				state.localFiles = [];
			}

			function clearRejectedFile(state, action) {
				if (action.payload.localId) {
					state.localFiles = state.localFiles.filter(
						item => item.id !== action.payload.localId
					);
				}

				state.images = [
					...state.images,
					{
						id: action.payload.localId,
						tempBlob: action.payload.blobUrl,
						error: action.payload,
						settings: defaultSettings,
						positions: defaultPositions
					}
				];

				const newSelectableId =
					state.images.findLast(item => !item.error)?.id || null;

				state.selectedImage = newSelectableId;
			}

			builder.addCase(thunks.removeBackground.fulfilled, handleRemove);
			builder.addCase(thunks.init.fulfilled, handleInit);
			builder.addCase(thunks.editImage.fulfilled, handleEdit);
			builder.addCase(thunks.resetImage.fulfilled, (state, action) => {
				state.images = getUpdatedImage(state.images, state.selectedImage, {
					...action.payload,
					cropped: undefined,
					settings: defaultSettings,
					positions: defaultPositions
				});
			});
			builder.addCase(thunks.applyBrush.fulfilled, (state, action) => {
				if (action.payload?.latest) {
					state.images = getUpdatedImage(
						state.images,
						state.selectedImage,
						image => ({
							...action.payload,
							cropped: undefined,
							settings: {
								...image?.settings,
								dirty: true
							}
						})
					);
				}
			});
			builder.addCase(thunks.removeBackground.rejected, clearRejectedFile);
			builder.addCase(thunks.undo.fulfilled, applyStack);
			builder.addCase(thunks.redo.fulfilled, applyStack);
			builder.addMatcher(
				isAsyncThunkActionWithPrefix('editor'),
				handleAsyncThunkActionState
			);
		}
	},
	initialState
);

export const {
	setFile,
	addImage,
	updateImageCroppedUrl,
	removeImage,
	selectImage,
	addLocalFiles,
	setZoom,
	setBackgroundImg,
	setBackgroundColor,
	setActiveBlur,
	setBlur,
	setBlurWidth,
	setBulk,
	setDownload,
	setSection,
	setSubSection,
	setTab,
	setMagicBrush,
	setBrushSize,
	setImageReady,
	clearImages,
	addImages,
	setEditView,
	setImageSize,
	addCustomBackground,
	removeCustomBackground,
	saveInStack,
	updateImageHasChanges,
	clearLocal,
	clearData,
	clearDropzoneProps,
	setDropzoneProps,
	updateBaseBg,
	setImagePositions,
	restorePositions,
	updateSettings,
	centerImage,
	setSelectionView
} = editorSlice.actions;

export default editorSlice.reducer;
