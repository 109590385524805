import { SvgIcon } from '@mui/material';

export function BrushIcon(props) {
	return (
		<SvgIcon
			width="25"
			height="25"
			viewBox="0 0 25 25"
			fill="none"
			{...props}
		>
			<path
				d="M6.692 20.806a4.107 4.107 0 0 1-1.697-.377 4.068 4.068 0 0 1-1.399-1.008c.382-.109.744-.34 1.085-.695.34-.354.511-.828.511-1.42 0-.699.242-1.29.726-1.774a2.413 2.413 0 0 1 1.774-.726c.699 0 1.29.242 1.774.726s.726 1.075.726 1.774c0 .962-.342 1.786-1.028 2.472-.685.685-1.51 1.028-2.472 1.028Zm0-1.5c.55 0 1.021-.196 1.413-.588.392-.391.587-.862.587-1.412a.968.968 0 0 0-.287-.713.968.968 0 0 0-.713-.287.968.968 0 0 0-.712.287.968.968 0 0 0-.288.713c0 .383-.045.733-.137 1.05a4.724 4.724 0 0 1-.363.9c.084.033.167.05.25.05h.25ZM12 15.248l-2.23-2.23 8.565-8.566a.977.977 0 0 1 .687-.287.93.93 0 0 1 .713.287l.83.83c.2.2.3.434.3.7 0 .267-.1.5-.3.7L12 15.249Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
