import { lazy, useEffect, Suspense, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Dialog, DialogContent, dialogClasses } from '@/components/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatPrice, showError } from '@/utils';
import {
	Box,
	CircularProgress,
	Divider,
	Drawer,
	Stack,
	Typography
} from '@mui/material';
import { useMatch } from 'react-router-dom';
import routes from '@/routes';
import { fetchPrices } from '@/store/billing/thunks';
import stripeModel from '@/models/stripe';
import { usePaymentMethod, useShowCurrencySelector } from '@/hooks';
import { CurrencySelector } from '@/components/CurrencySelector';
import { usePaymentUtils } from '@/components/PaymentElement/hooks';
import { Button } from '@/components/Button';
import { IconButton } from '@/components/IconButton';
import { getCheapestPlan, getDiscount } from '@/utils/plans';
import { useMedia } from '@/hooks/responsive';
import { CloseIcon } from '@/components/Icons';
import { getValueIfRtl, removeValueIfRtl } from '@/utils/rtlStyle';
import { TrialPlan } from './TrialPlan';
import { useStickyTop } from './hooks';
import { BUTTON_CONTAINER_HEIGHT } from './constants';

const PaymentElement = lazy(() => import('@/components/PaymentElement'));

export function FastCheckout(customProps) {
	const { t } = useTranslation();
	const fastEditor = !!useMatch(routes.fastCheckout);
	const fastDashboard = !!useMatch(routes.fastCheckoutDashboard);
	const smDown = useMedia('smDown');
	const dialogRef = useStickyTop(smDown);

	const isFastCheckoutRoute = fastEditor || fastDashboard;

	const { currency } = useSelector(state => state.auth);
	const showCurrencySelector = useShowCurrencySelector();
	const { executePayment, paymentError, executingPayment, clearError } =
		usePaymentUtils();

	const { loading, data: prices } = useSelector(
		state => state.billing.fetchPrices
	);

	const paymentMethod = usePaymentMethod();

	const dispatch = useDispatch();

	const [nextAction, setNextAction] = useState(null);
	const [stripeModule, setStripeModule] = useState(null);
	const [showCheckoutButton, setShowCheckoutButton] = useState(true);

	const cheapestPlan = getCheapestPlan(prices);

	const trialPlan = prices?.find(
		price => price.name === 'trial' && !!price.active
	);

	const trialPlanPrice = formatPrice({
		amount: trialPlan?.baseAmount,
		currency: currency || trialPlan?.currency
	});

	const discount = getDiscount(trialPlan, cheapestPlan);

	const loadingRender = (
		<Box
			sx={{
				minHeight: 130,
				display: 'flex',
				justifyContent: 'center'
			}}
		>
			<CircularProgress />
		</Box>
	);

	function handleClose(_, reason) {
		if (reason !== 'backdropClick') {
			window.location.href = fastDashboard
				? routes.dashboard
				: routes.editor;
		}
	}

	useEffect(() => {
		if (!isFastCheckoutRoute) {
			return;
		}

		(async () => {
			try {
				await dispatch(fetchPrices({ currency })).unwrap();
			} catch (err) {
				showError(err);
			}
		})();
	}, [isFastCheckoutRoute, currency]);

	useEffect(() => {
		if (!trialPlan || nextAction) {
			return;
		}

		(async () => {
			try {
				const response = await stripeModel.getNextAction(trialPlan?.id);

				setNextAction(response);
			} catch (error) {
				showError(error);
			}
		})();
	}, [trialPlan]);

	if (!isFastCheckoutRoute) {
		return null;
	}

	const content = (
		<>
			{loading && loadingRender}
			{!loading && (
				<Box sx={{ '& form': { pr: 0 } }}>
					<Box mb={3}>
						<Typography
							sx={{
								fontSize: '18px',
								fontWeight: 700,
								lineHeight: '28px',
								maxWidth: '80%'
							}}
						>
							{t('signUp.fastCheckoutPromotion', {
								credits: trialPlan?.credits
							})}
						</Typography>
						<Box mt="14px" pb={1.25}>
							<Stack
								flexDirection="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<Box>
									<Typography
										variant="body3"
										fontSize={{ xs: 16, sm: 20 }}
										fontWeight={700}
									>
										{t('common.payment')}
									</Typography>
								</Box>

								{showCurrencySelector && (
									<CurrencySelector
										sx={theme => ({
											'& .MuiOutlinedInput-notchedOutline': {
												border: 'none'
											},
											'& .MuiSelect-iconOutlined': {
												fontSize: 16
											},
											'&& .MuiInputBase-input': {
												fontSize: 12,
												paddingRight: removeValueIfRtl({
													theme,
													value: '32px',
													defaultValue: 3.5
												}),
												paddingLeft: getValueIfRtl({
													theme,
													value: 3.5
												})
											},
											'.MuiSelect-icon': {
												color: '#B8B8B8',
												right: removeValueIfRtl({
													theme,
													value: '7px'
												}),
												left: getValueIfRtl({ theme, value: '7px' })
											}
										})}
										style={{ minWidth: 45 }}
									/>
								)}
							</Stack>
						</Box>
						<Divider orientation="horizontal" />
						{trialPlan && (
							<TrialPlan
								trialPlan={trialPlan}
								trialPlanPrice={trialPlanPrice}
								currency={currency}
								discount={discount}
							/>
						)}
					</Box>
					<Suspense fallback={loadingRender}>
						<PaymentElement
							onSavedPaymentMethod={stripe => {
								if (!paymentMethod.hasPaymentMethod) {
									return executePayment(stripe, {
										priceId: trialPlan.id,
										nextAction
									});
								}
								setShowCheckoutButton(true);
							}}
							onLoadStripeModule={setStripeModule}
							onCancelPaymentMethodChange={() =>
								setShowCheckoutButton(true)
							}
							onOpenSetupIntent={() => {
								clearError();
								setShowCheckoutButton(false);
							}}
							onSetupErr={showError}
							currentPlan={trialPlan}
							finishButtonProps={{
								text: t('common.payNow'),
								fullWidth: true
							}}
							fastCheckout
						/>
						{paymentError && (
							<Typography
								variant="body1"
								color="error.main"
								sx={{ mp: 0 }}
							>
								{paymentError.message || paymentError.data?.message}
							</Typography>
						)}
						{showCheckoutButton && paymentMethod.hasPaymentMethod && (
							<Stack
								direction="row"
								justifyContent="flex-end"
								sx={theme => ({
									left: 0,
									p: '12px 24px 20px',
									[theme.breakpoints.up('sm')]: {
										position: 'absolute',
										top: 'var(--sticky-top)',
										width: '100%',
										transition: 'none'
									},
									[theme.breakpoints.down('sm')]: {
										position: 'fixed',
										bottom: 0,
										width: '100%',
										borderTop: '1px solid #E8E8E8',
										boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)'
									}
								})}
							>
								<Button
									variant="contained"
									fullWidth
									onClick={() =>
										executePayment(stripeModule, {
											priceId: trialPlan.id,
											nextAction
										})
									}
									loading={executingPayment}
								>
									{t('common.payNow')}
								</Button>
							</Stack>
						)}
					</Suspense>
				</Box>
			)}
		</>
	);

	if (smDown) {
		return (
			<Drawer
				open
				onClose={handleClose}
				anchor="bottom"
				sx={{
					'.MuiPaper-root': {
						borderRadius: '12px 12px 0px 0px',
						px: 3,
						pt: 5,
						pb: 3.5,
						height: { xs: 'calc(100svh - 24px)', sm: 'auto' },
						maxHeight: 656
					}
				}}
			>
				<IconButton
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						zIndex: 1
					}}
				>
					<CloseIcon sx={{ color: 'secondary.main' }} />
				</IconButton>
				{content}
			</Drawer>
		);
	}

	return (
		<Dialog
			ref={dialogRef}
			open
			onClose={handleClose}
			sx={{
				[`.${dialogClasses.paper}`]: {
					width: '100%',
					pt: 3,
					pb: { xs: 2, sm: 1 },
					px: 1,
					maxWidth: 520,
					overflow: 'hidden'
				}
			}}
			maxWidth="xl"
			width="100%"
			{...customProps}
		>
			<Scrollbars
				className="dialog-scrollbars"
				hideTracksWhenNotNeeded
				autoHeight
				autoHeightMax={`calc(100vh - ${20 + BUTTON_CONTAINER_HEIGHT}px)`}
				renderTrackHorizontal={props => (
					<div
						{...props}
						style={{ display: 'none' }}
						className="track-horizontal"
					/>
				)}
				renderView={props => (
					<div
						{...props}
						style={{
							...props.style,
							marginLeft: getValueIfRtl({
								defaultValue: 0,
								value: '-17px'
							}),
							marginRight: getValueIfRtl({
								value: 0,
								defaultValue: '-17px'
							})
						}}
					/>
				)}
			>
				<DialogContent
					sx={{
						px: {
							xs: 1,
							sm: 3
						}
					}}
				>
					{content}
				</DialogContent>
			</Scrollbars>
		</Dialog>
	);
}
