import { SvgIcon } from '@mui/material';

export function SelectIcon(props) {
	return (
		<SvgIcon
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			{...props}
		>
			<path
				d="m17.78 12.225-3.12-4.169c-.05-.075-.162-.075-.225 0L11.94 11.39c-.05.075-.162.075-.225 0L9.892 8.967c-.05-.074-.162-.074-.225 0l-2.446 3.258c-.063.088 0 .225.112.225h10.336c.112 0 .174-.125.112-.225Z"
				fill="currentColor"
			/>
			<path
				d="M19.24 3H5.76A2.259 2.259 0 0 0 3.5 5.26v13.48A2.259 2.259 0 0 0 5.76 21H13v-1.298H5.76a.962.962 0 0 1-.962-.961V5.259c0-.536.437-.96.961-.96h13.482a.97.97 0 0 1 .96.96V12.5H21.5V5.26A2.259 2.259 0 0 0 19.24 3Z"
				fill="currentColor"
			/>
			<path
				d="M20.21 15.292V14H14.5v5.722h1.292v-3.519l4.038 4.038.911-.9-4.049-4.049h3.519Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
